<template>
  <div class="card p-3 shadow-sm">
    <form @submit.prevent="addGeneralDetails" class="w-100">
      <div class="row" v-if="isAllowed('accident-incident.general.edit') && isOwnVessel()">
      <div class="col-12 text-right">
          <button class="e-btn e-btn-outlined-red ml-2" type="button" v-if="toEdit" @click="cancel">Cancel
          </button>
          <button class="e-btn e-btn-green ml-2" type="button" v-else @click="editValues">Edit General Details</button>
          <button class="e-btn e-btn-blue ml-2" type="submit" v-if="toEdit">Save</button>
        </div>
      </div>
      <table class="w-100">
        <tr>
          <td style="width: 25%">Owner</td>
          <td>
<!--            <input v-if="toEdit" type="text" class="form-control form-control-sm" placeholder="Owner"-->
<!--                   v-model="generalDetails.owner">-->
            <span>{{ accidentIncidentReport.owner }}</span>
          </td>
        </tr>
        <tr>
          <td>Charterer <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td>
            <input v-if="toEdit" type="text" class="form-control form-control-sm" placeholder="Charterer"
                   v-model="generalDetails.charterer">
            <span v-else>{{ accidentIncidentReport.charterer }}</span>
          </td>
        </tr>
      </table>
      <table class="w-100">
<!--        <tr>-->
<!--          <td style="width: 25%">Management Branch Office</td>-->
<!--          <td class="text-uppercase">-->
<!--            <span>-->
<!--            {{ accidentIncidentReport.managementOffice ? accidentIncidentReport.managementOffice : '' }}-->
<!--          </span>-->
<!--          </td>-->
<!--        </tr>-->
      </table>
      <hr/>
      <div v-if="!nonCms()" class="row justify-content-between">
        <div class="col-auto">
          <h6 class="h6">CMS Information</h6>
        </div>
        <div class="col-auto text-right">
          <div class="mb-1"  v-if="isAllowed('accident-incident.general.view.crew.info') && isOwnVessel()">
            <button class="e-btn e-btn-blue ml-2" type="button" data-toggle="modal" data-target="#crewListInformation" :disabled="crewKeys.length > 0">Crew List Information</button>
          </div>
          <div  v-if="isAllowed('accident-incident.general.view.crew.exp') && isOwnVessel()">
            <button class="e-btn e-btn-blue ml-2" type="button" data-toggle="modal" @click.prevent="getCrewExperienceLists" :disabled="crewKeys.length > 0">Crew Experience List</button>
          </div>
        </div>
      </div>
      <table class="w-100">
        <template v-if="nonCms()">
          <tr>
            <td colspan="100%">
              <h6 class="h6">Onboard crew information if CMS is not available</h6>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">Manning Company</td>
            <td>
              {{ nonCmsManning('name').toUpperCase() }}
            </td>
          </tr>
          <tr>
            <td style="width: 25%">Crew Numbers</td>
            <td>
              <input v-if="toEdit" type="number" class="form-control form-control-sm" placeholder="Crew Numbers" v-model="generalDetails.crew_numbers">
              <span v-else>{{ parseInt(accidentIncidentReport.crew_numbers ? accidentIncidentReport.crew_numbers : 0) }}</span>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td style="width: 25%">Name of Master</td>
            <td>
              <p class="p-0 m-0" v-for="master in master">{{ master.last_name }}, {{ master.first_name }}
                {{ master.middle_name }}</p>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">Name of C/E</td>
            <td>
              <p class="p-0 m-0" v-for="chiefEngineer in chiefEngineer">{{ chiefEngineer.last_name }}, {{ chiefEngineer.first_name }}
                {{ chiefEngineer.middle_name }}</p>
            </td>
          </tr>
          <tr>
            <td>Crew Numbers</td>
            <td>
              <p class="p-0 m-0" v-for="manningCount in manningCrewCount">{{ manningCount.name }} -
                <strong>{{ manningCount.count }}</strong></p>
            </td>
            <td>Total Crew Onboard:</td>
            <td><p class="p-0 m-0">{{manningFromOnboard}}</p></td>
          </tr>
        </template>
      </table>

      <div class="modal fade" id="crewListInformation" tabindex="-1" aria-labelledby="crewListInformationLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="crewListInformationLabel">Crew List Information as of {{formattedEventDate}}</h5>
<!--              <button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
<!--                <span aria-hidden="true">&times;</span>-->
<!--              </button>-->
            </div>
            <div class="modal-body">
              <table class="table table-bordered table-responsive-lg">
                <thead>
                  <tr>
                    <th class="align-middle text-center" scope="col" style="width: 7%;">RANK</th>
                    <th class="align-middle text-center" style="width: 15%" scope="col">NAME</th>
                    <th class="align-middle text-center" style="width: 7%" scope="col">IPN</th>
                    <th class="align-middle text-center" style="width: 4%" scope="col">EMBARK DATE</th>
                    <th class="align-middle text-center" style="width: 10%" scope="col">TIME ONBOARD AT TIME OF REPORT</th>
                    <th class="align-middle text-center" style="width: 25%" scope="col">MANNING</th>
                    <th class="align-middle text-center" style="width: 5%" scope="col">NATIONALITY</th>
                  </tr>
                </thead>
                <tbody>
                    <tr v-for="(crew,index) in crewKeys">
                      <td class="align-middle text-center">{{crew.rank.toUpperCase()}}</td>
                      <td class="align-middle" style="white-space: nowrap">
                        <router-link :to="{name:'CrewInfo',params:{id:crew.id}}" target="_blank" v-if="isAllowed('accident-incident.general.view.crew.info-link')">
                          {{crew.last_name}}, {{crew.first_name}} {{crew.middle_name.substring(0,1).toUpperCase()}}.
                        </router-link>
                        <span v-else>
                          {{crew.last_name}}, {{crew.first_name}} {{crew.middle_name.substring(0,1).toUpperCase()}}.
                        </span>
                      </td>
                      <td class="align-middle text-center">{{crew.ipn.toUpperCase()}}</td>
                      <td class="align-middle text-center">{{crew.embarkationDate}}</td>
<!--                      <td class="align-middle text-center">{{crew.disembarkationDate}}</td>-->
                      <td class="align-middle text-center">{{crew.duration.toUpperCase()}}</td>
                      <td class="align-middle text-center" style="white-space: nowrap">{{crew.manning.toUpperCase()}}</td>
                      <td class="align-middle text-center">{{crew.nationality.toUpperCase()}}</td>
                    </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="crewExperienceInfo" tabindex="-1" aria-labelledby="crewExperienceInfoLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="crewListInformationLabel">Crew Experience List as of {{formattedEventDate}}</h5>
<!--              <button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
<!--                <span aria-hidden="true">&times;</span>-->
<!--              </button>-->
            </div>
            <div class="modal-body" style="max-height:80vh; overflow-y: auto;">
              <template v-for="(crew,index) in crewLists">
                <experience-ui :crew="crew"></experience-ui>
              </template>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>


      <!--    <hr />-->
      <!--    <table class="w-100">-->
      <!--      <tr>-->
      <!--        <td style="width: 25%">Place</td>-->
      <!--        <td>-->
      <!--          <input v-if="toEdit" type="text" class="form-control form-control-sm" placeholder="At Sea / At Port" v-model="generalDetails.place">-->
      <!--          <span v-else>{{accidentIncidentReport.place}}</span>-->
      <!--        </td>-->
      <!--      </tr>-->
      <!--      <tr>-->
      <!--        <td>Latitude/Longtitude</td>-->
      <!--        <td><div class="row">-->
      <!--          <div class="col">-->
      <!--            <input v-if="toEdit" type="text" class="form-control form-control-sm" placeholder="Latitude" v-model="generalDetails.latitude">-->
      <!--            <span v-else>{{accidentIncidentReport.latitude}}</span>-->
      <!--          </div>-->
      <!--          <div class="col">-->
      <!--            <input v-if="toEdit" type="text" class="form-control form-control-sm" placeholder="Longtitude" v-model="generalDetails.longitude">-->
      <!--            <span v-else>{{accidentIncidentReport.longitude}}</span>-->
      <!--          </div>-->
      <!--        </div></td>-->
      <!--      </tr>-->
      <!--      <tr>-->
      <!--        <td>Sea Route (From)</td>-->
      <!--        <td>-->
      <!--          <input v-if="toEdit" type="text" class="form-control form-control-sm" placeholder="Sea Route From" v-model="generalDetails.sea_route_from">-->
      <!--          <span v-else>{{accidentIncidentReport.sea_route_from}}</span>-->
      <!--        </td>-->
      <!--      </tr>-->
      <!--      <tr>-->
      <!--        <td>Sea Route (To)</td>-->
      <!--        <td>-->
      <!--          <input v-if="toEdit" type="text" class="form-control form-control-sm" placeholder="Sea Route To" v-model="generalDetails.sea_route_to">-->
      <!--          <span v-else>{{accidentIncidentReport.sea_route_to}}</span>-->
      <!--        </td>-->
      <!--      </tr>-->
      <!--    </table>-->
      <!--    <hr />-->
      <!--    <table class="w-100">-->
      <!--      <tr>-->
      <!--        <td style="width: 25%">Responsible</td>-->
      <!--        <td class="text-capitalize">-->
      <!--          <select v-if="toEdit" name="" class="form-control form-control-sm text-uppercase" v-model="generalDetails.responsible_id">-->
      <!--            <option :value="null">——Select Responsible——</option>-->
      <!--            <option v-for="user in userKeys" :key="user.id" :value="user.id"> {{user.last_name}}, {{user.first_name}}</option>-->
      <!--          </select>-->
      <!--          <span v-else>-->
      <!--            {{accidentIncidentReport.responsible_detail ? accidentIncidentReport.responsible_detail.last_name : ''}}-->
      <!--            {{accidentIncidentReport.responsible_detail ? ', ' : ''}}-->
      <!--            {{accidentIncidentReport.responsible_detail ? accidentIncidentReport.responsible_detail.first_name : ''}}-->
      <!--          </span>-->
      <!--        </td>-->
      <!--      </tr>-->
      <!--      <tr>-->
      <!--        <td>Report Author</td>-->
      <!--        <td class="text-capitalize">-->
      <!--          <select v-if="toEdit" name="" class="form-control form-control-sm text-uppercase" v-model="generalDetails.report_author_id">-->
      <!--            <option :value="null">——Select Report Author——</option>-->
      <!--            <option v-for="user in userKeys" :key="user.id" :value="user.id"> {{user.last_name}}, {{user.first_name}}</option>-->
      <!--          </select>-->
      <!--          <span v-else>-->
      <!--            {{accidentIncidentReport.report_author_detail ? accidentIncidentReport.report_author_detail.last_name : ''}}-->
      <!--            {{accidentIncidentReport.report_author_detail ? ', ' : ''}}-->
      <!--            {{accidentIncidentReport.report_author_detail ? accidentIncidentReport.report_author_detail.first_name : ''}}-->
      <!--          </span>-->
      <!--        </td>-->
      <!--      </tr>-->
      <!--      <tr>-->
      <!--        <td>Person In Charge</td>-->
      <!--        <td class="text-capitalize">-->
      <!--          <select v-if="toEdit" name="" class="form-control form-control-sm text-uppercase" v-model="generalDetails.person_in_charge_id">-->
      <!--            <option :value="null">——Select Person In Charge——</option>-->
      <!--            <option v-for="user in userKeys" :key="user.id" :value="user.id"> {{user.last_name}}, {{user.first_name}}</option>-->
      <!--          </select>-->
      <!--          <span v-else>-->
      <!--            {{accidentIncidentReport.person_in_charge_detail ? accidentIncidentReport.person_in_charge_detail.last_name : ''}}-->
      <!--            {{accidentIncidentReport.person_in_charge_detail ? ', ' : ''}}-->
      <!--            {{accidentIncidentReport.person_in_charge_detail ? accidentIncidentReport.person_in_charge_detail.first_name : ''}}-->
      <!--          </span>-->
      <!--        </td>-->
      <!--      </tr>-->
      <!--    </table>-->
    </form>
    <search-crew/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import SearchCrew from '../../../components/layouts/SearchCrew'
import {ReportService} from '../../../services/ReportService'
import {AlertService} from '../../../services/AlertService'
import {DateService} from "@/services/DateService";
import ExperienceUi from "@/components/crew/experience/ExperienceUi";
import {KeyService} from "@/services/KeyService";
import {permissionMixin} from "@/mixins/permissionMixins";

export default {
  name: 'General',
  components: {
    'search-crew': SearchCrew,
    'experience-ui':ExperienceUi
  },
  mixins:[permissionMixin],
  data() {
    return {
      rankIdMaster: 1, // for capt/master
      rankIdCE: 2,
      generalDetails: {
        charterer: '',
      },
      reportId: this.$route.params.id,
      toEdit: false,
      crewLists:{},
    }
  },
  methods: {
    ...mapActions(['getAccidentIncidentReport']),
    nonCmsManning(key) {
      const nonCmsMannings = this.accidentIncidentReport?._vessel?.non_cms_mannings;

      if (nonCmsMannings != null && nonCmsMannings && Object.keys(nonCmsMannings).length && nonCmsMannings[Object.keys(nonCmsMannings)[0]].hasOwnProperty(key)) {
        return String(nonCmsMannings[Object.keys(nonCmsMannings)[0]][key]);
      }

      return '';
    },
    async cancel() {
      if (await AlertService.cancelAlert()) {
        this.toEdit = false;
      }
    },
    async addGeneralDetails() {
      if (await AlertService.confirmUpdateAlert()) {
        this.generalDetails.report_id = this.reportId
        await ReportService.updateAccidentIncidentReport(this.generalDetails)
        AlertService.successAlert('Updating General Details', 'UPDATE')
        await this.getAccidentIncidentReport(this.reportId)
        this.toEdit = false
      }
    },
    async editValues() {
      this.toEdit = true
      if (this.toEdit) {
        const params={
          id:this.accidentIncidentReport.id,
          charterer:this.accidentIncidentReport.charterer ? this.accidentIncidentReport.charterer : '',
          vessel_id:this.accidentIncidentReport.vessel_id,
          title:this.accidentIncidentReport.title,
          author:this.accidentIncidentReport.author,
          person_in_charge_id:this.accidentIncidentReport.person_in_charge_id,
          responsible_person_id:this.accidentIncidentReport.responsible_person_id,
          event_class_id:this.accidentIncidentReport.event_class_id,
          event_date:this.accidentIncidentReport.event_date,
          event_time:this.accidentIncidentReport.event_time,
          timezone_id:this.accidentIncidentReport.timezone_id,
          place:this.accidentIncidentReport.place,
          latitude:this.accidentIncidentReport.latitude,
          longitude:this.accidentIncidentReport.longitude,
          route_from:this.accidentIncidentReport.latitude,
          route_to:this.accidentIncidentReport.latitude,
          crew_numbers:this.accidentIncidentReport.crew_numbers,
        }
        this.generalDetails = Object.assign({}, params);
      }
    },
    async getCrewExperienceLists(){
      const params={
        onboard_from:this.accidentIncidentReport.event_date,
        onboard_to:this.accidentIncidentReport.event_date,
        vesselId:this.accidentIncidentReport.vessel_id,
        hasImage:true,
      }
      swal.fire({
        'title':'FETCHING CREW INFORMATION',
        allowEscapeKey:false,
        allowOutsideClick:false,
        allowEnterKey:false
      })
      swal.showLoading()
      const response = await KeyService.getCrewKeys(params)
      if(response){
        $("#crewExperienceInfo").modal('show');
        this.crewLists= response.crews
      }
      swal.close();
    },
    nonCms: function() {
      const nonCmsMannings = this.accidentIncidentReport?._vessel?.non_cms_mannings;

      return (nonCmsMannings != null && nonCmsMannings && Object.keys(nonCmsMannings).length > 0);
    }
  },

  computed: {
    ...mapGetters(['mngmntOfficeKeys', 'userKeys', 'accidentIncidentReport', 'crewKeys', 'manningFromOnboard', 'manningCrewCount']),
    master() {
      return Object.values(this.crewKeys).filter((crew) => crew.rank_id == this.rankIdMaster);
    },
    chiefEngineer() {
      return Object.values(this.crewKeys).filter((crew) => crew.rank_id == this.rankIdCE);
    },

    formattedEventDate(){
      return DateService.dateEliteFormat(this.accidentIncidentReport.event_date)
    }
  },
  destroyed() {
    $('#crewListInformation').modal('hide');
  }
}
</script>

<style scoped>
table td {
  padding: 2px;
}
</style>
